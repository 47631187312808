import React from 'react';
import QuestionText from './QuestionText';
import FrequencyButtons from './FrequencyButtons';

import './QuestionComponent.css';

const QuestionComponent = ({ question, onAnswer }) => {
  const handleAnswer = (score) => {
    onAnswer(score);
  };

  return (
    <div className="question-and-button">
      <QuestionText question={question} />
      <FrequencyButtons onAnswer={handleAnswer} />
    </div>
  );
};

export default QuestionComponent;