import React, { useState } from 'react';

import { Button } from '@mui/material';
import './userInfoForm.css'; // Import the CSS file


const UserInfoForm = ({ onSubmit }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedMinistries, setSelectedMinistries] = useState([]);
  const [otherMinistry, setOtherMinistry] = useState(''); // State for the "Other" ministry input
  const [showOtherInput, setShowOtherInput] = useState(false); // State to show/hide "Other" input
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const ministries = [
    'Children',
    'Student',
    'Life Groups',
    'Worship',
    'Hospitality',
    'Usher/Greeter',
    'Bible Studies',
    'Administration',
    'Facilities Maintenance',
  ];

  const handleMinistryChange = (ministry) => {
    if (ministry === 'Other') {
      setShowOtherInput(!showOtherInput); // Toggle visibility of the "Other" input
      if (!showOtherInput) {
        setSelectedMinistries([...selectedMinistries, ministry]);
      } else {
        setSelectedMinistries(selectedMinistries.filter((m) => m !== ministry));
        setOtherMinistry(''); // Clear the "Other" input when unchecked
      }
    } else {
      if (selectedMinistries.includes(ministry)) {
        setSelectedMinistries(selectedMinistries.filter((m) => m !== ministry));
      } else {
        setSelectedMinistries([...selectedMinistries, ministry]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullName || !email) {
      setError('Please fill in all required fields.');
      return;
    }

    const ministriesToSubmit = [...selectedMinistries];
    if (showOtherInput && otherMinistry) {
      ministriesToSubmit.push(otherMinistry);
    }

    setError('');

    try {
      console.log('Submitting user info:', {
        fullName,
        email,
        ministries: ministriesToSubmit,
      });

      if (onSubmit) {
        await onSubmit({
          fullName,
          email,
          ministries: ministriesToSubmit,
        });
      } else {
        console.error('onSubmit function is not provided.');
      }

      setSuccess('Information submitted successfully!');
    } catch (error) {
      console.error('Error during form submission:', error);
      setError('Failed to submit information. Please try again.');
    }
  };

  return (
    <form className="user-info-form" onSubmit={handleSubmit}>
      <h2>Contact Information</h2>
      <p>To receive results please complete the following:</p>

      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}

      <div className="form-group">
        <label>Full Name:</label>
        <input
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Which Ministries are you interested in serving?</label>
        {ministries.map((ministry) => (
          <div key={ministry} className="ministry-option">
            <input
              type="checkbox"
              id={ministry}
              value={ministry}
              checked={selectedMinistries.includes(ministry)}
              onChange={() => handleMinistryChange(ministry)}
            />
            <label htmlFor={ministry}>{ministry}</label>
          </div>
        ))}

        {/* Add "Other" option */}
        <div className="ministry-option">
          <input
            type="checkbox"
            id="other"
            value="Other"
            checked={selectedMinistries.includes('Other')}
            onChange={() => handleMinistryChange('Other')}
          />
          <label htmlFor="other">Other</label>
        </div>

        {/* Show the input for "Other" ministry when checked */}
        {showOtherInput && (
          <div className="form-group">
            <label>Please specify:</label>
            <input
              type="text"
              value={otherMinistry}
              onChange={(e) => setOtherMinistry(e.target.value)}
              placeholder="Enter ministry"
            />
          </div>
        )}
      </div>

      <Button variant='contained' type="submit">Submit</Button>
    </form>
  );
};

export default UserInfoForm;
