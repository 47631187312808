import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SurveyPage from './pages/SurveyPage';
import IntroPage from './pages/IntroPage'; // Import the IntroPage component
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Define routes here */}
          <Route path="/waterstone/spiritual-gifts/start" element={<SurveyPage />} />
          <Route path="/waterstone/spiritual-gifts" element={<IntroPage />} /> {/* Add IntroPage route */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
