import React, { useEffect, useState } from 'react';
import logo from '../../src/data/ws_logo.png';
import './results.css'; // Import the CSS file

const ResultsPage = ({ answers }) => {
  const [countdown, setCountdown] = useState(15); // Initial countdown set to 10 seconds

  const categoryScores = answers.reduce((totals, answer) => {
    totals[answer.category] = (totals[answer.category] || 0) + answer.score;
    return totals;
  }, {});

  const sortedCategories = Object.keys(categoryScores).sort(
    (a, b) => categoryScores[b] - categoryScores[a]
  );

  const topThree = sortedCategories.slice(0, 3);

  useEffect(() => {
    // Countdown timer for redirect
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Redirect after 15 seconds
    const redirectTimer = setTimeout(() => {
      window.location.href = 'https://mywaterstone.church';
    }, 15000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, []);

  return (
    <div className="results-container">
            <h2>Thank you for completing the test!</h2>
      <img 
        src={logo} 
        alt="Logo" 
        className="results-logo"
      />


      {topThree.length > 0 && (
        <div className="results-top-three">
  <h3>Your Top 3 Categories</h3>
  <p className="top-category" id="first">{topThree[0]}</p>
  {topThree.length > 1 && (
    <p className="top-category" id="second">{topThree[1]}</p>
  )}
  {topThree.length > 2 && (
    <p className="top-category" id="third">{topThree[2]}</p>
  )}
</div>

      )}

      <p>Your results have been emailed.<br />
      If you do not receive them, please <u>check your spam folder.</u></p>
      <p className="results-redirect">Returning to mywaterstone.church in ... {countdown} seconds</p>
    </div>
  );
};

export default ResultsPage;
