import React from 'react';

import { Button } from '@mui/material';


const FrequencyButtons = ({ onAnswer }) => {
  return (
    <div className="frequency-buttons">
      <Button variant="contained" onClick={() => onAnswer(1)}>Rarely</Button>
      <Button variant="contained" onClick={() => onAnswer(2)}>Infrequently</Button>
      <Button variant="contained" onClick={() => onAnswer(3)}>Occasionally</Button>
      <Button variant="contained" onClick={() => onAnswer(4)}>Frequently</Button>
    </div>
  );
};

export default FrequencyButtons;