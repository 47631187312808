import React, { useState, useEffect } from 'react';
import { Button, Box, LinearProgress, Typography, Card, CardContent } from '@mui/material';
import axios from 'axios';
import QuestionComponent from '../components/QuestionComponent';
import ResultsPage from '../components/Results';
import UserInfoForm from '../components/UserInfoForm';
import questions from '../data/questions';
import './SurveyPage.css';

const SurveyPage = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [remainingQuestions, setRemainingQuestions] = useState([]);
  const [isSurveyComplete, setIsSurveyComplete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const shuffled = [...questions].sort(() => Math.random() - 0.5);
    setRemainingQuestions(shuffled);
  }, []);

  const handleAnswer = (score) => {
    const newAnswers = [...answers, { score, category: remainingQuestions[currentQuestionIndex].category }];
    setAnswers(newAnswers);
    const newRemainingQuestions = [...remainingQuestions];
    newRemainingQuestions.splice(currentQuestionIndex, 1);
    setRemainingQuestions(newRemainingQuestions);
    setCurrentQuestionIndex(0);
  };

  const handleBack = () => {
    if (answers.length > 0) {
      const newAnswers = [...answers];
      newAnswers.pop();
      setAnswers(newAnswers);
      const lastAnsweredQuestion = questions.find(
        (q) => q.category === answers[answers.length - 1].category
      );
      setRemainingQuestions([lastAnsweredQuestion, ...remainingQuestions]);
      setCurrentQuestionIndex(0);
    }
  };

  const handleSkip = () => {
    if (remainingQuestions.length > 1) {
      setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % remainingQuestions.length);
    }
  };

  const calculateCategoryScores = () => {
    return answers.reduce((acc, { category, score }) => {
      acc[category] = (acc[category] || 0) + score;
      return acc;
    }, {});
  };

  const getAllScores = () => {
    const categoryScores = calculateCategoryScores();
    return Object.entries(categoryScores)
      .map(([category, score]) => ({ category, score }))
      .sort((a, b) => b.score - a.score);
  };
  
  const getTopCategories = () => {
    const categoryScores = calculateCategoryScores();
    return Object.entries(categoryScores)
      .map(([category, score]) => ({ category, score }))
      .sort((a, b) => b.score - a.score)
      .slice(0, 3);
  };

  const generateResultFiles = (categories) => {
    return categories.map(({ category }) => ({
      name: `${category}.pdf`
    }));
  };
  

  const handleUserInfoSubmit = async (info) => {
    try {
      if (!info || !info.fullName || !info.email || !Array.isArray(info.ministries)) {
        throw new Error('Incomplete user information. Please provide all required fields.');
      }
  
      setUserInfo(info);
      setIsSurveyComplete(true);

      
      const allScores = getAllScores();
      const topCategories = getTopCategories();
      const resultFiles = generateResultFiles(topCategories);

  
      const emailData = {
        userInfo: {
          name: info.fullName,
          email: info.email,
          ministries: info.ministries,
        },
        resultFiles,
        topCategories,
        allScores,

      };
      console.log('Top Categories:', topCategories);
      console.log('All Scores:', allScores);
      console.log('Results:', resultFiles);
  
      await axios.post(`${process.env.REACT_APP_API_URL}/send-email`, emailData);
    } catch (error) {
      console.error('Error sending survey results:', error);
      setError('Failed to send survey results. Please try again later.');
    }
  };

  const progress = Number(((answers.length / questions.length) * 100).toFixed(2));

  if (remainingQuestions.length === 0 && !userInfo) {
    return <UserInfoForm onSubmit={handleUserInfoSubmit} />;
  }

  if (isSurveyComplete) {
    return <ResultsPage answers={answers} userInfo={userInfo} allScores={getAllScores()} />;
  }

  return (
    <Box className="survey-container">
      <h2>WaterStone Church</h2>
      <h5>Spiritual Gifts Assessment</h5>

      <Card className="survey-card">
        <CardContent>
          <QuestionComponent
            question={remainingQuestions[currentQuestionIndex]}
            onAnswer={handleAnswer}
          />
        </CardContent>
      </Card>

      <Box className="survey-controls">
        <Box className="nav-button-group">
          <Button variant="outlined" onClick={handleBack} disabled={answers.length === 0}>
            Back
          </Button>
          <Button variant="outlined" onClick={handleSkip} disabled={remainingQuestions.length <= 1}>
            Skip
          </Button>
        </Box>

        <Box className="progress-bar-container">
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" align="center" className="progress-text">
            {progress}% completed
          </Typography>
        </Box>

        {error && (
          <Typography color="error" variant="body2" align="center" className="error-message">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SurveyPage;
