import React from 'react';

const QuestionText = ({ question }) => {
  // Check if 'question' and 'question.text' are defined
  if (!question || !question.text) {
    return <div className="question-text">No question available</div>;
  }

  return (
    <div className="question-text">
      <h3>{question.text}</h3>
    </div>
  );
};

export default QuestionText;
